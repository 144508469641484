// settings
@import "settings/mixins";
@import "settings/colors";
@import "settings/typography";
@import "settings/variables";

.cpbulba-home{
    .cpbulba-blackbar{
        background: transparent;
    }
    .cpbulba-header{
        background: transparent;
        @include media("tablet-wide", min){
            height: 5.5em;
            .logo{
                font-size: 4em;
            }
            nav{
                > ul{
                    > li[data-type="aim"],
                    > li[data-type="media"],
                    > li[data-type="apps"],{
                        > ul{
                            top: 5.5em;
                            border-radius: 1rem;
                        }
                    }
                }
            }
        }
    }
    .cpbulba-home__searchbox{
        margin: -7.5rem 1rem auto;
    }
}